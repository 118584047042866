import { Icon } from "@iconify/react";
import React from "react";
import InputForm from "src/components/FormComponents/inputForm";
import { FullscreenOnMobile } from "src/components/FullscreenOnMobile";

interface MobileFlightToSearchProps {
  isShown: boolean;
  setisShown: any;
  errors: any;
  data: any;
  handleFlightChange: any;
  flightList: any;
  isLoading: boolean;
  handleshowFlightList: any;
}

export const MobileFlightToSearch = ({
  isShown,
  setisShown,
  errors,
  data,
  handleFlightChange,
  flightList,
  isLoading,
  handleshowFlightList,
}: MobileFlightToSearchProps) => {
  return (
    <>
      <FullscreenOnMobile
        className='one'
        content={
          <div className="text-center my-5">
            {isLoading && data.showToList && (
              <Icon icon="eos-icons:loading" width="30" height="30" />
            )}
            {flightList.length > 0 && data.showToList && (
              <ul className="list-none cursor-pointer  rounded-box">
                {flightList.map((eachList: any, eachIndex: any) => (
                  <li
                    className="text-base flex mb-5 px-5 items-center"
                    onClick={(e) => {
                      handleFlightChange({
                        target: {
                          name: "flight_to_id",
                          value: eachList.value,
                          text: eachList.text,
                          data: eachList,
                        },
                      });
                      setisShown(false);
                    }}
                    key={eachIndex + 1 + "to"}
                  >
                    <span>
                      <Icon icon="mdi:flight" className="pr-2.5 w-8 h-8" />
                    </span>
                    <span>{eachList.text}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        }
        header={
          <>
            <div className="flex">
              <strong className="m-auto">Search To Location</strong>
              <button
                onClick={() => {
                  setisShown(false);
                  handleshowFlightList(false, "showToFlightList");
                }}
              >
                <Icon icon="akar-icons:cross" />
              </button>
            </div>
            <div className="mt-5">
              <InputForm
                name="to"
                label="To"
                errors={errors}
                showErrorLable={false}
                value={data.to || ""}
                handleChange={handleFlightChange}
                placeholder={"Search by city/airport"}
                iconClass={`top-[2px]  left-2`}
                className={`pl-7`}

              />
            </div>
          </>
        }
        isShown={isShown}
        setisShown={setisShown}
      />
    </>
  );
};
